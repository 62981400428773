import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ErrorMessage from '../../../ui/ErrorMessage';
import * as api from './api';
import Recurring, { RecurringData } from './Recurring';

const DataContainer = () => {
    const [processing, setProcessing] = useState<boolean>();
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {}, []);

    const onSubmit = async (userId: string): Promise<RecurringData> => {
        setProcessing(true);
        try {
            const { body, status } = await api.getRecurring(userId);
            const { body: balances, status: statusBalances } = await api.getBalances(userId);
            if (status >= 400 || statusBalances >= 400) {
                setErrorMessage(
                    `received code ${status} while retrieving user ${userId} (${new Date().getTime()})`
                );
                setProcessing(false);
                return {};
            }
            setProcessing(false);
            return { transactions: body, balances };
        } catch (err) {
            setErrorMessage(`${err} (${new Date().getTime()})`);
        }
        setProcessing(false);
        return {};
    };

    return (
        <>
            <ErrorMessage message={errorMessage} />
            <Recurring processing={processing} onSubmit={onSubmit} />
        </>
    );
};

export default DataContainer;
